<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :offset-y="true"
      content-class="sortColumnClass"
      max-width="280px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="table-button unsetCapitalize"
          outlined
          color="primary"
          v-bind="attrs"
          v-bind:class="{ expanded: menu, collapsed: !menu }"
          @click="setColumns()"
          v-on="on"
        >
          <span> {{ title }} </span>
          <span v-if="selectedTitle != null" class="textDecorationBold">
            {{ ": " + selectedTitle }}
          </span>
          <span>
            <Icon
              :icon="menu ? 'bxs:chevron-up' : 'bxs:chevron-down'"
              height="17"
              outline
              :color="menu ? 'white' : '#263573'"
            />
          </span>
        </v-btn>
      </template>

      <v-container>
        <v-row v-for="column in sortColumns" :key="column.value" class="noPad">
          <v-col class="pt- pb-0 pl-1 pr-0" align="left">
            {{ $t(column.text) }}
          </v-col>
          <v-col cols="4" sm="4" md="4" lg="4" class="pa-0 mt-2">
            <v-btn icon color="primary" @click="setSort(column)">
              <Icon
                :icon="
                  column.sort == 'asc'
                    ? 'bx:chevron-up'
                    : column.sort == 'desc'
                    ? 'bx:chevron-down'
                    : 'akar-icons:chevron-vertical'
                "
                height="15"
                outline
                color="#263573"
              />
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              class="table-button"
              outlined
              color="primary"
              @click="updateSortColumns()"
            >
              {{
                $t("update")
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-menu>
  </div>
</template>

<script>
/*
 Name:SortColumns
 Description: sort choices for report display 

 API Property:
    title: Title of select list
    selectedTitle: String display for selected item
    columns: Array, // Array of columns
    signleChoice: Single selection if true, Multiple Selection if false
    onSubmit: Function onsubmit

  Components Used:
    Icon
*/
import { mapGetters } from "vuex";
import { Icon } from "@iconify/vue2";
import i18n from "../../i18n/i18n";
export default {
  name: "SortColumns",
  components: {
    Icon,
  },
  props: {
    title: { type: String, default: i18n.t("showSortOption") }, // Title of select list
    selectedTitle: String,
    columns: Array, // Array of columns
    signleChoice: { type: Boolean, default: false }, // Single selection if true, Multiple Selection if false
    onSubmit: Function, // Function onsubmit
  },
  data() {
    return {
      menu: false,
      sortColumns: [],
    };
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    setColumns() {
      if (!this.menu) {
        this.sortColumns = [];
        this.sortColumns = JSON.parse(
          JSON.stringify(
            this.columns.filter((m) => {
              return m.customSortable;
            })
          )
        );
      }
    },
    setSort(column) {
      column.sort =
        column.sort == "asc" ? "desc" : column.sort == "desc" ? "" : "asc";
      if (this.signleChoice)
        this.sortColumns = this.sortColumns.map((m) => {
          if (m.value != column.value) m.sort = "";
          return m;
        });
    },
    updateSortColumns() {
      this.menu = false;
      this.onSubmit(this.sortColumns);
    },
  },
};
</script>
<style lang="scss" scoped>
.fontSize15 {
  font-size: 15px;
}
.sortColumnClass {
  background-color: white;
  border: 2px solid #253472;
  border-radius: 10px;
}

.unsetCapitalize {
  text-transform: unset !important;
}

.collapsed {
  border: 0px;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
}
.expanded {
  background-color: #253472;
  color: white !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
}
.noPad {
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 12px !important;
}
</style>
