<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :offset-y="true"
      content-class="headerColumnClass"
      max-width="280px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="table-button unsetCapitalize"
          outlined
          color="primary"
          v-bind="attrs"
          v-bind:class="{ expanded: menu, collapsed: !menu }"
          @click="setSelected()"
          v-on="on"
        >
          {{ $t("showHeadrOption") }}
          <Icon
            :icon="menu ? 'bxs:chevron-up' : 'bxs:chevron-down'"
            height="17"
            outline
            :color="menu ? 'white' : '#263573'"
          />
        </v-btn>
      </template>

      <v-container>
        <v-row
          v-for="header in filteredHeaders"
          :key="header.value"
          class="noPad"
        >
          <v-col class="pa-0">
            <v-checkbox
              hide-details
              class="custom--checkbox--text"
              v-model="selected"
              :label="$t(header.text)"
              :value="header.value"
            >
            </v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              class="table-button"
              outlined
              color="primary"
              @click="updateHeaders()"
            >
              {{
                $t("update")
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-menu>
  </div>
</template>

<script>
/*
 Name:HeaderColumns
 Description: Headers choices for report display 

 API Property:
    headers: Array of headers
    selectedHeaderColumns: Witch objects from array of headers are selected
    onSubmit: Function onsubmit

  Components Used:
    Icon
*/
import { mapGetters } from "vuex";
import { Icon } from "@iconify/vue2";
export default {
  name: "HeaderColumns",
  components: {
    Icon,
  },
  props: {
    headers: Array, // Array of headers
    selectedHeaderColumns: Array, // Witch objects from array of headers are selected
    onSubmit: Function, // Function onsubmit
  },
  data() {
    return {
      menu: false,
      selected: [],
    };
  },
  computed: {
    filteredHeaders() {
      return this.headers.filter((m) => {
        if (m.text != "" && m.value != "actions" && m.value != "unRead") {
          return m;
        }
      });
    },
    ...mapGetters([]),
  },
  methods: {
    setSelected() {
      if (!this.menu) {
        this.selected = [];
        this.selectedHeaderColumns.forEach((e) => {
          if (e.text != "" && e.value != "actions") this.selected.push(e.value);
        });
      }
    },
    updateHeaders() {
      try {
        let newHeaderColumns = this.headers.filter(
          (hd) =>
            this.selected.includes(hd.value) ||
            hd.text == "" ||
            hd.value == "actions"
        );
        this.onSubmit(newHeaderColumns);
      } catch {
        this.onSubmit(this.headers);
      }
      this.menu = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.headerColumnClass {
  background-color: white;
  border: 2px solid #253472;
  border-radius: 10px;
}
.v-label {
  color: rgb(38 53 115) !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
}
.collapsed {
  border: 0px;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
}
.expanded {
  background-color: #253472;
  color: white !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
}
.noPad {
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 12px !important;
}
label.v-label.theme--light {
  color: rgb(38 53 115) !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
}
</style>
