<template>
  <v-navigation-drawer
    width="360px"
    height="100%"
    class="filterDrawer"
    right
    fixed
    v-model="show"
    app
    temporary
  >
    <v-col>
      <v-row class="justify-between">
        <v-col v-if="moreFeatures" class="pb-0" align="left">
          <v-icon @click="toggleMoreFeatures" class="topIcons">
            mdi-chevron-left
          </v-icon>
        </v-col>
        <v-col class="pb-0" align="right">
          <v-icon @click="closeHandler" class="topIcons primaryColorOnHover">
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pl-0 pr-0 pb-0">
          <v-list-item class="pa-0">
            <v-expansion-panels v-model="openedPanel" accordion>
              <v-expansion-panel v-if="!moreFeatures">
                <v-expansion-panel-header style="background-color: #ebebeb">
                  <v-row>
                    <v-col>
                      <h5
                        class="textDecorationBold primaryColor textDecorationBold"
                        style="font-size: 16px !important"
                      >
                        {{ $t("propertyType") }}
                      </h5>
                    </v-col>
                  </v-row>
                  <template v-slot:actions>
                    <v-icon color="primary"> $expand </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  align="left"
                  class="panelOverflow"
                >
                  <!-- :class="viewBreakpoint ? '' : 'panelOverflow'" -->
                  <v-row>
                    <v-col class="pb-0">
                      <h6
                        style="font-size: 14px !important"
                        class="text-uppercase textDecorationBold"
                      >
                        {{ $t("availableFor") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-btn-toggle v-model="availableEstates" flex multiple>
                        <v-btn
                          v-for="button in availableForList"
                          :key="button.text"
                          :value="button.id"
                          class="ma-1 btn"
                          outlined
                        >
                          {{ $t(button.text) }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6
                        style="font-size: 14px !important"
                        class="text-uppercase textDecorationBold"
                      >
                        {{
                          $t("propertyCategories")
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-btn-toggle v-model="propertyCategories">
                        <v-btn
                          v-for="button in realEstateCategoriesList"
                          :key="button.text"
                          :value="button.id"
                          class="ma-1 btn"
                          outlined
                        >
                          {{ $t(button.text) }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6
                        class="textDecorationBold"
                        style="font-size: 14px !important"
                      >
                        {{ $t("allSubCategories") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      propertyCategories == undefined ||
                      propertyCategories == null
                    "
                  >
                    <v-col class="pt-0">
                      <span style="font-size: 14px">
                        {{ $t("subpropertyCategoriesLabel") }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row v-else class="pb-12">
                    <v-col class="pb-12 pt-0">
                      <v-btn-toggle v-model="subCategory" multiple>
                        <v-btn
                          v-for="button in getSubCategoryList"
                          :key="button.text"
                          :value="button.id"
                          class="ma-1 btn"
                          outlined
                        >
                          {{ $t(button.text) }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="!moreFeatures">
                <v-expansion-panel-header style="background-color: #ebebeb">
                  <v-row>
                    <v-col>
                      <h5
                        class="textDecorationBold primaryColor textDecorationBold"
                        style="font-size: 16px !important"
                      >
                        {{ $t("featuresFilters") }}
                      </h5>
                    </v-col>
                  </v-row>
                  <template v-slot:actions>
                    <v-icon color="primary"> $expand </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  align="left"
                  class="panelOverflow"
                >
                  <!-- :class="viewBreakpoint ? '' : 'panelOverflow'" -->
                  <v-row
                    v-if="[0, 1, 2, 3, 4].includes(this.categoryId)"
                    class="ml-n6 mr-n6"
                  >
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 1, 2, 3, 4].includes(this.categoryId)">
                    <v-col class="pt-0 pb-0">
                      <h6 class="textDecorationBold" style="font-size: 14px">
                        {{ $t("price") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 1, 2, 3, 4].includes(this.categoryId)">
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="priceMin"
                        height="30"
                        dense
                        :label="$t('from')"
                        outlined
                        clearable
                        append-icon="mdi-currency-eur"
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="priceMax"
                        height="30"
                        dense
                        :label="$t('toFilter')"
                        outlined
                        append-icon="mdi-currency-eur"
                        clearable
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="[0, 3].includes(this.categoryId)"
                    class="ml-n6 mr-n6"
                  >
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 3].includes(this.categoryId)">
                    <v-col class="pt-0 pb-0">
                      <h6 class="textDecorationBold" style="font-size: 14px">
                        {{ $t("areasqm") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 3].includes(this.categoryId)">
                    <v-col class="pt-0">
                      <v-radio-group v-model="areaType">
                        <v-row>
                          <v-col>
                            <v-radio
                              value="meters2"
                              :label="$t('meters2')"
                              class="textDecorationBold mCard"
                              color="#e89008"
                            />
                          </v-col>
                          <v-col>
                            <v-radio
                              value="acres"
                              :label="$t('acres')"
                              class="textDecorationBold mCard"
                              color="#e89008"
                            />
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 3].includes(this.categoryId)">
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="areasSqmMin"
                        height="30"
                        dense
                        :label="$t('from')"
                        outlined
                        clearable
                        :suffix="
                          areaType == 'meters2' ? $t('sqft3') : $t('acres2')
                        "
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="areasSqmMax"
                        height="30"
                        dense
                        :label="$t('toFilter')"
                        outlined
                        :suffix="
                          areaType == 'meters2' ? $t('sqft3') : $t('acres2')
                        "
                        clearable
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="[0, 1, 2, 4].includes(this.categoryId)"
                    class="ml-n6 mr-n6"
                  >
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 1, 2, 4].includes(this.categoryId)">
                    <v-col class="pt-0 pb-0">
                      <h6 class="textDecorationBold" style="font-size: 14px">
                        {{ $t("sqft") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 1, 2, 4].includes(this.categoryId)">
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="areaMin"
                        height="30"
                        dense
                        :label="$t('from')"
                        outlined
                        clearable
                        append-icon="mdi-map-marker"
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="areaMax"
                        height="30"
                        dense
                        :label="$t('toFilter')"
                        outlined
                        clearable
                        append-icon="mdi-map-marker"
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="[0, 1, 2, 4].includes(this.categoryId)"
                    class="ml-n6 mr-n6"
                  >
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 1, 2, 4].includes(this.categoryId)">
                    <v-col class="pt-0 pb-0">
                      <h6 class="textDecorationBold" style="font-size: 14px">
                        {{ $t("price") + " / " + $t("sqft") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 1, 2, 4].includes(this.categoryId)">
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="areaPriceMin"
                        height="30"
                        dense
                        :label="$t('from')"
                        outlined
                        clearable
                        append-icon="mdi-currency-eur"
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="areaPriceMax"
                        height="30"
                        dense
                        :label="$t('toFilter')"
                        outlined
                        clearable
                        append-icon="mdi-currency-eur"
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="[0, 1, 2, 4].includes(this.categoryId)"
                    class="ml-n6 mr-n6"
                  >
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 1, 2, 4].includes(this.categoryId)">
                    <v-col class="pt-0 pb-0">
                      <h6 class="textDecorationBold" style="font-size: 14px">
                        {{ $t("floors") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 1, 2, 4].includes(this.categoryId)">
                    <v-col class="pt-0">
                      <v-select
                        v-model="floorMin"
                        :items="floorsList"
                        :label="$t('from')"
                        outlined
                        height="30"
                        dense
                        clearable
                        hide-details="auto"
                        item-value="val"
                      >
                        <template v-slot:selection="{ item }">
                          <v-row>
                            <v-col>
                              <span>
                                {{ $t(item.text) }}
                              </span>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0">
                      <v-select
                        v-model="floorMax"
                        :items="floorsList"
                        :label="$t('toFilter')"
                        outlined
                        height="30"
                        dense
                        clearable
                        hide-details="auto"
                        item-value="val"
                      >
                        <template v-slot:selection="{ item }">
                          <v-row>
                            <v-col>
                              <span>
                                {{ $t(item.text) }}
                              </span>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="[0, 2, 4].includes(this.categoryId)"
                    class="ml-n6 mr-n6"
                  >
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 2, 4].includes(this.categoryId)">
                    <v-col class="pt-0 pb-0">
                      <h6 class="textDecorationBold" style="font-size: 14px">
                        {{ $t("numberOfPlaces") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 2, 4].includes(this.categoryId)">
                    <v-col class="pt-0">
                      <v-select
                        v-model="numberOfPlacesMin"
                        :items="getRoomList"
                        :label="$t('from')"
                        outlined
                        height="30"
                        dense
                        clearable
                        hide-details="auto"
                        item-value="val"
                      >
                        <template v-slot:selection="{ item }">
                          <v-row>
                            <v-col>
                              <span>
                                {{ $t(item.text) }}
                              </span>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0">
                      <v-select
                        v-model="numberOfPlacesMax"
                        :items="getRoomList"
                        :label="$t('toFilter')"
                        outlined
                        height="30"
                        dense
                        clearable
                        hide-details="auto"
                        item-value="val"
                      >
                        <template v-slot:selection="{ item }">
                          <v-row>
                            <v-col>
                              <span>
                                {{ $t(item.text) }}
                              </span>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="[0, 1].includes(this.categoryId)"
                    class="ml-n6 mr-n6"
                  >
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 1].includes(this.categoryId)">
                    <v-col class="pt-0 pb-0">
                      <h6
                        class="textDecorationBold text-uppercase"
                        style="font-size: 14px"
                      >
                        {{
                          $t("bedrooms")
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 1].includes(this.categoryId)">
                    <v-col class="pt-0">
                      <v-select
                        v-model="roomMin"
                        :items="getRoomList"
                        :label="$t('from')"
                        outlined
                        height="30"
                        dense
                        clearable
                        hide-details="auto"
                        item-text="text"
                        item-value="val"
                      />
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0">
                      <v-select
                        v-model="roomMax"
                        :items="getRoomList"
                        :label="$t('toFilter')"
                        outlined
                        height="30"
                        dense
                        clearable
                        hide-details="auto"
                        item-text="text"
                        item-value="val"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="[0, 1].includes(this.categoryId)"
                    class="ml-n6 mr-n6"
                  >
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 1].includes(this.categoryId)">
                    <v-col class="pt-0 pb-0">
                      <h6
                        class="text-uppercase textDecorationBold"
                        style="font-size: 14px"
                      >
                        {{
                          $t("bathrooms")
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 1].includes(this.categoryId)">
                    <v-col class="pt-0">
                      <v-select
                        v-model="bathroomMin"
                        :items="getRoomList"
                        :label="$t('from')"
                        outlined
                        height="30"
                        dense
                        clearable
                        hide-details="auto"
                        item-text="text"
                        item-value="val"
                      />
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0">
                      <v-select
                        v-model="bathroomMax"
                        :items="getRoomList"
                        :label="$t('toFilter')"
                        outlined
                        height="30"
                        dense
                        clearable
                        hide-details="auto"
                        item-text="text"
                        item-value="val"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="[0, 1, 2, 4].includes(this.categoryId)"
                    class="ml-n6 mr-n6"
                  >
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 1, 2, 4].includes(this.categoryId)">
                    <v-col class="pt-0 pb-0">
                      <h6
                        class="text-uppercase textDecorationBold"
                        style="font-size: 14px"
                      >
                        {{
                          $t("yearOfConstruction")
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 1, 2, 4].includes(this.categoryId)">
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="yearMin"
                        height="30"
                        dense
                        label="XXXX"
                        outlined
                        clearable
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="yearMax"
                        height="30"
                        dense
                        label="XXXX"
                        outlined
                        clearable
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 1, 2, 4].includes(this.categoryId)">
                    <v-col class="pt-0">
                      <v-checkbox
                        v-for="button in buildingStatusDisplay"
                        :key="button.val"
                        v-model="buildingStatus"
                        :label="$t(button.text)"
                        :value="button.val"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="[0, 2, 4].includes(this.categoryId)"
                    class="ml-n6 mr-n6"
                  >
                    <v-col class="pl-0 pr-0 pb-0 pt-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 2, 4].includes(this.categoryId)">
                    <v-col class="pt-0 pb-0">
                      <h6
                        class="textDecorationBold text-uppercase"
                        style="font-size: 14px"
                      >
                        {{ $t("more") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 2, 4].includes(this.categoryId)">
                    <v-col class="pt-0">
                      <v-checkbox
                        v-for="button in availabilityList"
                        :key="button.val"
                        v-model="statusSelected"
                        :label="$t(button.text)"
                        :value="button.id"
                        multiple
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="[0, 3].includes(this.categoryId)"
                    class="ml-n6 mr-n6"
                  >
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 3].includes(this.categoryId)">
                    <v-col class="pt-0 pb-0">
                      <h6
                        style="font-size: 14px !important"
                        class="textDecorationBold"
                      >
                        {{ $t("build") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 3].includes(this.categoryId)">
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="buildFrom"
                        height="30"
                        dense
                        :label="$t('from') + '.'"
                        outlined
                        clearable
                        color="primary"
                        hide-details="auto"
                        :suffix="$t('sqft3')"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="buildTo"
                        height="30"
                        dense
                        :label="$t('toDt2') + '...'"
                        outlined
                        clearable
                        color="primary"
                        hide-details="auto"
                        :suffix="$t('sqft3')"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="[0, 3].includes(this.categoryId)"
                    class="ml-n6 mr-n6"
                  >
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 3].includes(this.categoryId)">
                    <v-col class="pt-0 pb-0">
                      <h6
                        style="font-size: 14px !important"
                        class="textDecorationBold"
                      >
                        {{ $t("otherFeatures2") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 3].includes(this.categoryId)" class="mt-0">
                    <v-col cols="12" class="pt-0">
                      <v-select
                        v-model="zoneSelected"
                        :items="zoneList"
                        :label="$t('selectzone')"
                        return-object
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-row>
                            <v-col>
                              <span v-if="index == 0">
                                {{ $t(item.text) }}
                              </span>
                              <span
                                v-if="zoneSelected.length > 1 && index == 0"
                                class="grey--text text-caption"
                              >
                                + {{ zoneSelected.length - 1 }}
                                {{ $t("moreSelected") }}
                              </span>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="[0, 3].includes(this.categoryId)"
                    class="ml-n6 mr-n6"
                  >
                    <v-col class="pl-0 pr-0 pb-0 pt-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 3].includes(this.categoryId)">
                    <v-col class="pt-0 pb-0">
                      <h6
                        class="textDecorationBold text-uppercase"
                        style="font-size: 14px"
                      >
                        {{ $t("more") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row v-if="[0, 3].includes(this.categoryId)">
                    <v-col class="pt-0">
                      <v-checkbox
                        v-for="button in buildingUseList"
                        :key="button.val"
                        v-model="buildingUse"
                        :label="$t(button.text)"
                        :value="button.id"
                        multiple
                      />
                    </v-col>
                  </v-row>
                  <v-row class="pb-12">
                    <v-col class="pb-12"> </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header style="background-color: #ebebeb">
                  <v-row>
                    <v-col>
                      <h5
                        class="textDecorationBold primaryColor"
                        style="font-size: 16px !important"
                      >
                        {{ $t("otherFilters") }}
                      </h5>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  align="left"
                  class="panelOverflow"
                >
                  <!-- :class="viewBreakpoint ? '' : 'panelOverflow'" -->
                  <v-row class="pt-6">
                    <v-col class="pt-0 pb-0">
                      <h6
                        style="font-size: 14px !important"
                        class="text-uppercase textDecorationBold"
                      >
                        {{ $t("interestedParties") }}
                      </h6>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <v-autocomplete
                        v-model="customer"
                        :label="$t('customerTelSearch')"
                        :items="customerList"
                        item-value="_id"
                        item-text="name"
                        :filter="filterCustomers"
                        return-object
                        :search-input.sync="searchCustomer"
                        :loading="customerAutocompleteLoader"
                        hide-no-data
                        clearable
                        flat
                        attach
                        @focus="focusOn('customer')"
                        ref="customer"
                        :menu-props="{
                          maxHeight: viewBreakpoint ? 200 : 400,
                        }"
                      >
                        <template v-slot:selection="{ item }">
                          <v-col class="flex-grow-0 text-no-wrap pa-0 font16">
                            <span>
                              {{ item.name }}
                            </span>
                          </v-col>
                        </template>
                        <template slot="item" slot-scope="data">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col class="font16">
                                  {{ data.item.name }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <v-autocomplete
                        v-model="agent"
                        :label="$t('responsiblePartner')"
                        :items="agentsList"
                        :filter="filterAgents"
                        item-value="_id"
                        item-text="fullname"
                        return-object
                        :search-input.sync="searchAgent"
                        :loading="autocompleteLoaderAgent"
                        hide-no-data
                        flat
                        clearable
                        hide-selected
                        attach
                        @focus="focusOn('agent')"
                        ref="agent"
                        :menu-props="{
                          maxHeight: viewBreakpoint ? 200 : 400,
                        }"
                      >
                        <template v-slot:selection="{ item }">
                          <v-col class="flex-grow-0 text-no-wrap pa-0 font16">
                            <span>
                              {{ item.LastName + " " + item.FirstName }}
                            </span>
                          </v-col>
                        </template>
                        <template slot="item" slot-scope="data">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col>
                                  <span class="font16">
                                    {{
                                      data.item.LastName +
                                      " " +
                                      data.item.FirstName
                                    }}
                                  </span>
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6
                        style="font-size: 14px !important"
                        class="text-uppercase textDecorationBold"
                      >
                        {{ $t("demandType") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-checkbox
                        v-for="button in demandTypeList"
                        :key="button.val"
                        v-model="askingTypeStatus"
                        :label="$t(button.text)"
                        :value="button"
                        multiple
                      />
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6
                        style="font-size: 14px !important"
                        class="text-uppercase textDecorationBold"
                      >
                        {{ $t("condition") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-btn-toggle v-model="publishedSelected">
                        <v-btn
                          v-for="button in conditionList"
                          :key="button.text"
                          :value="button.val"
                          class="ma-1 btn"
                          outlined
                        >
                          {{ $t(button.text) }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6
                        style="font-size: 14px !important"
                        class="text-uppercase textDecorationBold"
                      >
                        {{ $t("renewalDate") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row class="mb-n6">
                    <v-col class="pt-0 pb-0 pr-0 pl-1 dateClearable">
                      <v-menu
                        ref="menu4"
                        v-model="menu4"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        left
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            outlined
                            clearable
                            single-line
                            :label="$t('dateForm')"
                            v-model="reNewDateFromFormat"
                            prepend-inner-icon="mdi-calendar"
                            class="mb-4"
                            @blur="
                              reNewDateFrom = parseDate(
                                reNewDateFromFormat,
                                'reNewDateFrom'
                              )
                            "
                            v-on:keyup.enter="onSetFilter"
                          >
                            <template v-slot:prepend-inner>
                              <v-icon v-bind="attrs" v-on="on"
                                >mdi-calendar</v-icon
                              >
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          locale="el"
                          v-model="reNewDateFrom"
                          scrollable
                          @input="menu4 = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0 pb-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0 pb-0 pl-0 pr-1 dateClearable">
                      <v-menu
                        ref="menu5"
                        v-model="menu5"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        left
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            outlined
                            clearable
                            single-line
                            :label="$t('dateForm')"
                            v-model="reNewDateToFormat"
                            prepend-inner-icon="mdi-calendar"
                            class="mb-4"
                            @blur="
                              reNewDateTo = parseDate(
                                reNewDateToFormat,
                                'reNewDateTo'
                              )
                            "
                            v-on:keyup.enter="onSetFilter"
                          >
                            <template v-slot:prepend-inner>
                              <v-icon v-bind="attrs" v-on="on"
                                >mdi-calendar</v-icon
                              >
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          locale="el"
                          v-model="reNewDateTo"
                          scrollable
                          @input="menu4 = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6 mt-0">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6
                        style="font-size: 14px !important"
                        class="text-uppercase textDecorationBold"
                      >
                        {{ $t("proposedRealEstate") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-3">
                      <v-text-field
                        v-model="realEstateCode"
                        height="30"
                        dense
                        label=""
                        outlined
                        clearable
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6
                        class="text-uppercase textDecorationBold"
                        style="font-size: 14px"
                      >
                        {{ $t("valuationF") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row class="pb-12">
                    <v-col class="pt-0 pb-12">
                      <v-select
                        v-model="ratingFrom"
                        :items="ratingFieldList"
                        :label="$t('from') + '...'"
                        outlined
                        height="30"
                        dense
                        clearable
                        hide-details="auto"
                        item-value="val"
                      >
                        <template v-slot:selection="{ item }">
                          <v-row class="ml-1">
                            <v-col class="rating pr-0 pl-0" align="left">
                              <v-rating
                                class="rating"
                                dense
                                color="#E89008"
                                background-color="#E89008 !important"
                                :length="1"
                                :readonly="true"
                                size="22"
                              />
                            </v-col>
                            <v-col class="pb-0 pl-1 mt-1 rating" align="right">
                              <span
                                class="font15 textDecorationBold"
                                style="color: #e89008"
                              >
                                {{ $t(item.text) }}
                              </span>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row class="ml-0">
                                <v-col class="rating pr-0 pl-0" align="left">
                                  <v-rating
                                    class="rating"
                                    dense
                                    color="#E89008"
                                    background-color="#E89008 !important"
                                    :length="1"
                                    :readonly="true"
                                    size="22"
                                  />
                                </v-col>
                                <v-col
                                  class="pb-0 pl-1 mt-1 mr-1 rating"
                                  align="right"
                                >
                                  <span
                                    class="font15 textDecorationBold"
                                    style="color: #e89008"
                                  >
                                    {{ $t(item.text) }}
                                  </span>
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0">
                      <v-select
                        v-model="ratingTo"
                        :items="ratingFieldList"
                        :label="$t('toDt2') + '...'"
                        outlined
                        height="30"
                        dense
                        clearable
                        hide-details="auto"
                        item-value="val"
                      >
                        <template v-slot:selection="{ item }">
                          <v-row class="ml-1">
                            <v-col class="rating pr-0 pl-0" align="left">
                              <v-rating
                                class="rating"
                                dense
                                color="#E89008"
                                background-color="#E89008 !important"
                                :length="1"
                                :readonly="true"
                                size="22"
                              />
                            </v-col>
                            <v-col class="pb-0 pl-1 mt-1 rating" align="right">
                              <span
                                class="font15 textDecorationBold"
                                style="color: #e89008"
                              >
                                {{ $t(item.text) }}
                              </span>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row class="ml-0">
                                <v-col class="rating pr-0 pl-0" align="left">
                                  <v-rating
                                    class="rating"
                                    dense
                                    color="#E89008"
                                    background-color="#E89008 !important"
                                    :length="1"
                                    :readonly="true"
                                    size="22"
                                  />
                                </v-col>
                                <v-col
                                  class="pl-1 mt-1 mr-1 rating"
                                  align="right"
                                >
                                  <span
                                    class="font15 textDecorationBold"
                                    style="color: #e89008"
                                  >
                                    {{ $t(item.text) }}
                                  </span>
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-list-item>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-footer inset padless absolute style="background-color: #ebebeb">
            <v-row class="pt-3 pb-3">
              <v-col>
                <v-btn
                  elevation="5"
                  align="center"
                  class="ma-1 btn"
                  color="error"
                  @click="onSetFilter"
                >
                  {{ $t("search") }}
                </v-btn>
              </v-col>
              <v-col class="align-self-center" aling="center">
                <a
                  class="text-decoration-underline errorColor"
                  @click="clearFilters"
                  >{{ $t("clear") }}</a
                >
              </v-col>
            </v-row>
          </v-footer>
        </v-col>
      </v-row>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
/*
 Name:Filters
 Description: Component for demand filters

 API Property: 
    onCloseHandler: Function to close the slider
    filterValues: Object with the filter values
    updateAttribute: Function to update data
    onSearch: Function on search
    filterType: Number for filters category opening

    Endpoints Functions Called:
      -ContactRepo
        get
        getAgents
        getCustomers  
*/
import { mapGetters, mapState } from "vuex";
import DemandsStaticLists from "@/static/demands/demands.js";
import Repository from "@/services/Repository";
import CoreHelper from "@/helpers/Core/core";

const ContactRepo = Repository.get("contacts");
const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  name: "DemandsFilters",
  props: {
    onCloseHandler: Function,
    filterValues: Object,
    updateAttribute: Function,
    onSearch: Function,
    filterType: Number,
  },
  created() {
    this.agentsList.push(this.agent);
    this.customerList.push(this.customer);

    this.reNewDateFromFormat = this.filterValues.reNewDateFrom;
    this.reNewDateToFormat = this.filterValues.reNewDateTo;
    this.propertyCategories = this.filterValues.category?.id
      ? this.filterValues.category.id
      : 0;
    this.openedPanel = this.filterType;
  },
  data() {
    return {
      customerList: [],
      searchCustomer: null,
      customerAutocompleteLoader: false,
      agentsList: [],
      searchAgent: null,
      autocompleteLoaderAgent: false,
      demandTypeList: DemandsStaticLists.demandTypeList,
      ratingFieldList: DemandsStaticLists.ratingFieldList,
      buildingStatusList: DemandsStaticLists.buildingStatusList,
      availabilityList: DemandsStaticLists.availabilityList,
      conditionSelected: null,
      conditionList: DemandsStaticLists.conditionList,
      buildingUseList: DemandsStaticLists.buildingUseList,
      reNewDateFromFormat: null,
      reNewDateToFormat: null,
      show: true,
      openedPanel: null,
      menu: false,
      menu1: false,
      menu4: false,
      menu5: false,
      moreFeatures: false,
      categoryId: 0,
      subCategoriesId: [],
    };
  },
  watch: {
    show() {
      if (!this.show) {
        this.closeHandler();
      }
    },
    reNewDateFrom() {
      this.reNewDateFromFormat = this.formatDate(this.reNewDateFrom);
      this.reNewDateFrom =
        this.reNewDateFrom == null || this.reNewDateFrom == undefined
          ? null
          : this.reNewDateFrom;
    },
    reNewDateTo() {
      this.reNewDateToFormat = this.formatDate(this.reNewDateTo);
      this.reNewDateTo =
        this.reNewDateTo == null || this.reNewDateTo == undefined
          ? null
          : this.reNewDateTo;
    },
    searchCustomer(val) {
      val && this.fetchCustomerList(val, "splitName");
    },
    searchAgent(val) {
      val && val != this.agent && this.fetchAgentsList(val);
    },
  },
  computed: {
    ...mapGetters([
      "realEstateCategoriesList",
      "generalFeaturesList",
      "availableForList",
      "floorsList",
      "getRoomList",
      "zoneList",
    ]),
    ...mapState({
      userDetails: (state) => state.auth.userDetails,
    }),
    viewBreakpoint() {
      return this.$vuetify.breakpoint.width < 800;
    },
    buildingStatusDisplay() {
      if ([2, 4].includes(this.categoryId) == true) {
        let array = this.buildingStatusList.filter((object) => object.id != 3);
        return array;
      }
      return this.buildingStatusList;
    },
    customer: {
      get() {
        return this.filterValues?.customer;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("customer", value);
      },
    },
    availableEstates: {
      get() {
        return this.filterValues?.available;
      },
      set(value) {
        this.updateAttribute(
          "available",
          value == null || value == undefined ? null : value
        );
      },
    },
    propertyCategories: {
      get() {
        return this.filterValues?.category.id;
      },
      set(value) {
        if (value == undefined) {
          this.categoryId = 0;
        } else {
          this.categoryId = value;
          this.updateAttribute(
            "category",
            value == null || value == undefined
              ? null
              : this.realEstateCategoriesList[value]
          );
        }
      },
    },
    subCategory: {
      get() {
        return this.filterValues?.subCategory;
      },
      set(value) {
        this.subCategoriesId = value;
        this.updateAttribute(
          "subCategory",
          value == null || value == undefined ? null : value
        );
      },
    },
    statusSelected: {
      get() {
        return this.filterValues?.statusSelected;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("statusSelected", value);
      },
    },
    reNewDateFrom: {
      get() {
        return this.filterValues?.reNewDateFrom;
      },
      set(value) {
        this.updateAttribute(
          "reNewDateFrom",
          value == null || value == undefined ? null : value
        );
      },
    },
    reNewDateTo: {
      get() {
        return this.filterValues?.reNewDateTo;
      },
      set(value) {
        this.updateAttribute(
          "reNewDateTo",
          value == null || value == undefined ? null : value
        );
      },
    },
    yearMin: {
      get() {
        return this.filterValues?.yearMin;
      },
      set(value) {
        this.updateAttribute(
          "yearMin",
          value == null || value == undefined ? null : value
        );
      },
    },
    yearMax: {
      get() {
        return this.filterValues?.yearMax;
      },
      set(value) {
        this.updateAttribute(
          "yearMax",
          value == null || value == undefined ? null : value
        );
      },
    },
    areasSqmMin: {
      get() {
        return this.filterValues?.areasSqmMin;
      },
      set(value) {
        this.updateAttribute(
          "areasSqmMin",
          value == null || value == undefined ? null : value
        );
      },
    },
    agent: {
      get() {
        return this.filterValues?.agent;
      },
      set(value) {
        this.updateAttribute(
          "agent",
          value == null || value == undefined ? null : value
        );
      },
    },
    areaType: {
      get() {
        return this.filterValues?.areaType;
      },
      set(value) {
        this.updateAttribute(
          "areaType",
          value == null || value == undefined ? null : value
        );
      },
    },
    areasSqmMax: {
      get() {
        return this.filterValues?.areasSqmMax;
      },
      set(value) {
        this.updateAttribute(
          "areasSqmMax",
          value == null || value == undefined ? null : value
        );
      },
    },
    assessmentMin: {
      get() {
        return this.filterValues?.assessmentMin;
      },
      set(value) {
        this.updateAttribute("assessmentMin", value);
      },
    },
    assessmentMax: {
      get() {
        return this.filterValues?.assessmentMax;
      },
      set(value) {
        this.updateAttribute("assessmentMax", value);
      },
    },
    publishedSelected: {
      get() {
        return this.filterValues?.publishedSelected;
      },
      set(value) {
        this.updateAttribute("publishedSelected", value);
      },
    },
    realEstateCode: {
      get() {
        return this.filterValues?.realEstateCode;
      },
      set(value) {
        this.updateAttribute("realEstateCode", value);
      },
    },
    priceMin: {
      get() {
        return this.filterValues?.priceMin;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("priceMin", value);
      },
    },
    zoneSelected: {
      get() {
        return this.filterValues?.zoneSelected;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("zoneSelected", value);
      },
    },
    priceMax: {
      get() {
        return this.filterValues?.priceMax;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("priceMax", value);
      },
    },
    areaMin: {
      get() {
        return this.filterValues?.areaMin;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("areaMin", value);
      },
    },
    areaMax: {
      get() {
        return this.filterValues?.areaMax;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("areaMax", value);
      },
    },
    ratingFrom: {
      get() {
        return this.filterValues?.ratingFrom;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("ratingFrom", value);
      },
    },
    ratingTo: {
      get() {
        return this.filterValues?.ratingTo;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("ratingTo", value);
      },
    },
    buildTo: {
      get() {
        return this.filterValues?.buildTo;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("buildTo", value);
      },
    },
    buildFrom: {
      get() {
        return this.filterValues?.buildFrom;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("buildFrom", value);
      },
    },
    areaPriceMin: {
      get() {
        return this.filterValues?.areaPriceMin;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("areaPriceMin", value);
      },
    },
    areaPriceMax: {
      get() {
        return this.filterValues?.areaPriceMax;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("areaPriceMax", value);
      },
    },
    numberOfPlacesMin: {
      get() {
        return this.filterValues?.numberOfPlacesMin;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("numberOfPlacesMin", value);
      },
    },
    numberOfPlacesMax: {
      get() {
        return this.filterValues?.numberOfPlacesMax;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("numberOfPlacesMax", value);
      },
    },
    floorMin: {
      get() {
        return this.filterValues?.floorMin;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("floorMin", value);
      },
    },
    floorMax: {
      get() {
        return this.filterValues?.floorMax;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("floorMax", value);
      },
    },
    roomMin: {
      get() {
        return this.filterValues?.roomMin;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("roomMin", value);
      },
    },
    roomMax: {
      get() {
        return this.filterValues?.roomMax;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("roomMax", value);
      },
    },
    bathroomMin: {
      get() {
        return this.filterValues?.bathroomMin;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("bathroomMin", value);
      },
    },
    bathroomMax: {
      get() {
        return this.filterValues?.bathroomMax;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("bathroomMax", value);
      },
    },
    proUse: {
      get() {
        return this.filterValues?.proUse;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("proUse", value);
      },
    },
    outOfPlan: {
      get() {
        return this.filterValues?.outOfPlan;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("outOfPlan", value);
      },
    },
    buildingStatus: {
      get() {
        return this.filterValues?.buildingStatus;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("buildingStatus", value);
      },
    },
    askingTypeStatus: {
      get() {
        return this.filterValues?.askingTypeStatus;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("askingTypeStatus", value);
      },
    },
    buildingUse: {
      get() {
        return this.filterValues?.buildingUse;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("buildingUse", value);
      },
    },
    getSubCategoryList() {
      return this.realEstateCategoriesList[this.categoryId].subCategoriesList;
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    toggleMoreFeatures() {
      this.moreFeatures = !this.moreFeatures;
      if (this.moreFeatures == true) this.openPanel(0);
    },
    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "reNewDateFrom") {
          this.reNewDateFrom = null;
          this.reNewDateFromFormat = null;
        } else if (dateName == "reNewDateTo") {
          this.reNewDateTo = null;
          this.reNewDateToFormat = null;
        }
        return null;
      }
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async fetchCustomerList(val, type) {
      try {
        let filters;
        this.customerAutocompleteLoader = true;
        if (![null, undefined, ""].includes(val) && type == "splitName") {
          if (val != null) {
            filters = {
              user_input: /*CoreHelper.toRegex(*/ val /*.split(" ")[0])*/,
            };
          }
        }
        let resp = await ContactRepo.getCustomers(filters, {
          page: 0,
          items: 20,
        });
        this.customerList = resp.data;
        this.customerAutocompleteLoader = false;
      } catch (e) {
        this.customerAutocompleteLoader = false;
        throw e;
      }
    },

    assessmentList(item, queryText, itemText) {
      if (queryText.length >= 1) {
        let queryWord = queryText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .split(" ");
        let itemWord = itemText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase();
        return queryWord.every((item) => itemWord.includes(item));
      }
    },
    filterCustomersByPhone(item, queryText, itemText) {
      if (queryText.length >= 1) {
        let queryWord = queryText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .split(" ");
        let itemWord = itemText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase();
        return queryWord.every((item) => itemWord.includes(item));
      }
    },
    filterAgents(item, queryText, itemText) {
      if (queryText.length >= 1) {
        let queryWord = queryText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .split(" ");
        let itemWord = itemText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase();
        return queryWord.every((item) => itemWord.includes(item));
      }
    },

    filterCustomers(item, queryText, itemText) {
      if (!isNaN(queryText)) {
        return item;
      }
      if (queryText.length >= 1) {
        let queryWord = queryText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .split(" ");
        let itemWord = itemText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase();
        return queryWord.every((item) => itemWord.includes(item));
      }
    },
    async fetchAgentsList(val) {
      let filters;
      if (this.userDetails.Role == "Admin") {
        filters = [
          { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
        ];
      } else if (
        ["Secretary", "OfficeAdmin", "HR"].includes(this.userDetails.Role)
      ) {
        filters = [
          { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
          [{ LastCompany: this.userDetails.Company }],
        ];
      } else if (this.userDetails.Role == "Leader") {
        let ids = [];
        if (this.userDetails.Team && this.userDetails.Team.length > 0)
          ids = [...this.userDetails.Team, this.userDetails._id];
        else ids = [this.userDetails._id];
        filters = {
          _id: { $in: ids },
        };
        if (val != null) {
          filters.LastName = {
            $regex: CoreHelper.toRegex(val.split(" ")[0]),
            $options: "i",
          };
        }
        let resp = await ContactRepo.get(filters, {
          page: 0,
          items: 60,
        });
        this.agentsList = resp.data.contacts.map((a) => {
          return { ...a, fullname: a.LastName + " " + a.FirstName };
        });
        return false;
      } else {
        this.agentsList = [];
        return false;
      }
      try {
        if (val != null) {
          if (filters.length == 1) {
            filters.push([]);
          }
          filters[1].push({
            ["LastName"]: {
              $regex: CoreHelper.toRegex(val.split(" ")[0]),
              $options: "i",
            },
          });
        }
        let resp = await ContactRepo.getAgents(filters, { page: 0, items: 60 });
        this.agentsList = resp.data.agents.map((a) => {
          return { ...a, fullname: a.LastName + " " + a.FirstName };
        });
        this.autocompleteLoaderAgent = false;
      } catch (e) {
        this.autocompleteLoaderAgent = false;
        throw e;
      }
    },
    closeHandler() {
      this.onCloseHandler();
    },
    onSetFilter() {
      this.onSearch("genericSearch");
    },
    clearFilters() {
      this.yearMin = null;
      this.yearMax = null;
      this.areaMin = null;
      this.areaMax = null;
      this.available = [];
      this.category = null;
      this.subCategory = null;
      this.priceMin = null;
      this.priceMax = null;
      this.assessmentMin = null;
      this.assessmentMax = null;
      this.publishedSelected = null;
      this.realEstateCode = null;
      this.areaPriceMin = null;
      this.areaPriceMax = null;
      this.numberOfPlacesMin = null;
      this.numberOfPlacesMax = null;
      this.floorMin = null;
      this.floorMax = null;
      this.roomMin = null;
      this.roomMax = null;
      this.bathroomMin = null;
      this.bathroomMax = null;
      this.buildingStatus = null;
      this.buildingUse = [];
      this.askingTypeStatus = [];
      this.statusSelected = [];
      this.proUse = false;
      this.outOfPlan = false;
      this.buildTo = null;
      this.buildFrom = null;
      this.areasSqmMin = null;
      this.areasSqmMax = null;
      this.ratingFrom = null;
      this.ratingTo = null;
      this.reNewDateFrom = null;
      this.reNewDateTo = null;
      this.zoneSelected = null;
      this.areaType = "meters2";
    },
    closeAllPanels() {
      this.openedPanel = null;
    },
    openPanel(index) {
      this.openedPanel = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.panelOverflow {
  height: calc(100vh - 278px);
  overflow: auto;
}

h6 {
  padding: 10px 0px 10px 0px;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
}

.btn {
  text-transform: unset !important;
  color: #263573;
}

.topIcons {
  cursor: pointer;
  color: #263573;
}

.v-expansion-panel--active + .v-expansion-panel,
.v-expansion-panel--active:not(:first-child) {
  margin-top: 0px;
}

.v-text-input {
  font-size: 14px;
}

.v-btn-toggle {
  border-radius: 4px;
  display: block;
  max-width: 100%;
}

.v-btn-toggle .v-btn.v-btn.v-size--default {
  min-width: 48px;
  min-height: 0;
}

.v-btn-toggle--group > .v-btn.v-btn {
  border-color: #263573 !important;
}

.v-btn-toggle > .v-btn.v-btn {
  border-radius: 5px !important;
  border-width: thin !important;
  background-color: white !important;
}

.v-input--selection-controls {
  margin: 0px 0px -16px 0px;
  padding-top: 0px;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: rgba(38, 53, 115, 1) !important;
  text-transform: capitalize !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  background-color: white;
}

.v-label {
  font-size: 13px;
}

.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 35px;
}

#active-button {
  background-color: #263573 !important;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
  background: #fff;
  color: #263573;
}

.primaryColor {
  color: #263573 !important;
}

.theme--light.v-btn-toggle.v-btn-toggle--group {
  background: #fff;
  color: #263573;
}

.divInputs {
  display: flex;
  padding-bottom: 10px;
}

.divInputsNoBottomPadding {
  display: flex;
  padding-bottom: 0px;
}

.v-select {
  font-size: 14px;
}

.rating {
  display: flex;
  justify-content: flex-start;
}
</style>
<style>
input {
  font-size: 18px;
}
</style>
